.ant-layout-header {
	display: flex;
	align-content: center;
	justify-content: space-between;
}
//底部trigger 触发
.ant-layout-sider-trigger {
	border-top: 1px solid #dddddd;
	text-align: left;
	padding: 0 20px;
}